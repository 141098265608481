<template>
  	<div>
    	<main class="container-fluid mt-0">
			<div class="text-center d-none d-md-block d-lg-block" style="position: absolute; top: 3vh; left: 44%; z-index: 1;">
				<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
			</div>
			<div class="row mx-md-4 my-4 d-flex align-items-center">
				<div class="col-md-8 text-center d-none d-md-block d-lg-block">
					<img src="@/assets/images/img-login.png" width="70%" />
				</div>
				<div class="col-md-12 d-md-none d-lg-none text-center pb-3">
					<router-link to="/"><img src="@/assets/images/logo.png" width="150px" /></router-link>
				</div>
				<div class="col-md-4">
					<div class="card shadow border-success round">
						<div class="card-body p-4">
							<div class="text-center py-2">
								<i class="fas fa-user-plus fa-5x text-success"></i>
							</div>
							<form class="px-3 py-2" @submit.prevent="register">
								<h4 class="text-center">Daftar Akun {{ account_type | capitalize }}</h4>
								<hr class="my-3 w-75" />
								<div class="w-100 mb-2" data-toggle="buttons">
									<label class="btn btn-outline-success active w-xl-48 w-lg-48 w-100 px-1 float-left">
										<input class="d-none" type="radio" v-model="account_type" value="personal" name="account_type" checked><i class="far fa-user fa-2x"></i><br/>Akun Personal
									</label>
									<label class="btn btn-outline-success w-xl-48 w-lg-48 w-100 px-1 float-right">
										<input class="d-none" type="radio" v-model="account_type" value="bussiness" name="account_type"><i class="fas fa-user-tie fa-2x"></i><br/>Akun Bisnis
									</label>
								</div>
								<div class="clearfix"></div>
								<label for="inlineFormInputGroupName" class="text-muted mb-1">Nama Lengkap</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-user text-success"></i></div>
									</div>
									<input type="text" class="form-control" v-model="fullname" id="inlineFormInputGroupName" placeholder="Nama Lengkap" required />
								</div>
								<label for="inlineFormInputGroupEmail" class="text-muted mb-1">Email</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-envelope text-success"></i></div>
									</div>
									<input type="email" class="form-control" v-model="email" id="inlineFormInputGroupEmail" placeholder="Email" required />
								</div>
								<label for="inlineFormInputGroupKataSandi" class="text-muted mb-1">Kata Sandi</label>
								<div class="input-group mb-2 mr-sm-2">
									<div class="input-group-prepend">
										<div class="input-group-text bg-white"><i class="fa fa-key text-success"></i></div>
									</div>
									<input type="password" v-model="password" class="form-control pr-5" id="inlineFormInputGroupKataSandi" placeholder="Kata Sandi" required />
								</div>
								<span toggle="#inlineFormInputGroupKataSandi" @click="togglePassword" class="fa fa-lg fa-eye toggle-password text-success"></span>
								<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" v-model="newsletter" id="customCheck1" />
									<label class="custom-control-label text-muted" for="customCheck1">Berlangganan Newsletter</label>
								</div>
								<div class="text-center mt-4">
									<button type="submit" class="btn btn-success w-100">Daftar</button>
									<p class="text-muted mt-3">Dengan mendaftar, <br />saya menyetujui <router-link to="/static/privacy-policy" class="text-success text-decoration-none">Kebijakan Privasi</router-link>.</p>
									<hr class="my-3 w-75" />
									<p class="text-muted mb-0">Sudah punya akun? <router-link to="/login" class="text-primary text-decoration-none">Masuk</router-link></p>
								</div>
							</form>
						</div>
					</div>
					<div class="text-center py-3"><a href="#" @click="$router.go(-1)" class="text-secondary text-decoration-none">Kembali</a></div>
				</div>
			</div>
		</main>
  	</div>
</template>

<script>

export default {
	data () {
 		return {
			account_type: 'personal',
			fullname: '',
			email: '',
			password: '',
			newsletter: false,
 		}
	},
	methods: {
		togglePassword: function() {
			$('span.toggle-password').toggleClass("fa-eye fa-eye-slash");
			let input = $($('span.toggle-password').attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			}
			else {
				input.attr("type", "password");
			}
		},
		register: function () {
			let formData = new FormData()
			formData.append('fullname', this.fullname)
			formData.append('email', this.email)
			formData.append('password', this.password)
			formData.append('newsletter', this.newsletter)
			formData.append('account_type', this.account_type)

			this.$store.dispatch('register', formData)
			.then(() => {
				this.fullname = '',
				this.email = '',
				this.password = '',
				this.newsletter = false,
				this.account_type = '',

				this.$swal({
					title: 'Berhasil Daftar', 
					html: '<p>Pengguna berhasil didaftarkan</p>',
					icon: 'success',
					timer: 2500,
					showConfirmButton: false,
				});
				this.$router.push('/login')
			})
      	 	.catch(() => {
				this.fullname = '',
				this.email = '',
				this.password = '',
				this.newsletter = false,
				this.account_type = '',

				this.$swal({
					title: 'Oops!', 
					html: '<p>Pengguna telah terdaftar</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
      	}
	},
	created() {
		$(document).scrollTop(0)
	}  
}
</script>